.pagination-container{
    float : right;
}




.article-content { 
    margin-top: "2em"
}

.article-content img {
        object-fit: cover;
        max-width : 100%;
}

.fullview-container {
    margin: 3em  9em ;  
    word-break: normal;
}

.loader {
    width: 48px;
    height: 48px;
    border: 5px solid var(--theme);
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    position: relative;
    animation: pulse 1s linear infinite;
  }
  .loader:after {
    content: '';
    position: absolute;
    width: 48px;
    height: 48px;
    border: 5px solid var(--theme);
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    animation: scaleUp 1s linear infinite;
  }
  
  @keyframes scaleUp {
    0% { transform: translate(-50%, -50%) scale(0) }
    60% , 100% { transform: translate(-50%, -50%)  scale(1)}
  }
  @keyframes pulse {
    0% , 60% , 100%{ transform:  scale(1) }
    80% { transform:  scale(1.2)}
  }


@media (max-width : 728px) {
    .pagination-container{
        float : unset;
    }

    .fullview-container {
        margin : 0
    }
    
}