.container { 
    background-color: white;
    color : black;
    display : grid;
    grid-template-columns: repeat(auto-fit , 300px);
    justify-content: center;
    margin-top: 15px;
    /* align-items: center; */
    gap : 1em
    /* padding : 2em; */
    
}


.achievement{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap : 0.5em;
    padding : 1em;
    border-radius : 10px;
    word-break: break-word;
    margin-bottom: 1em;
    text-align: center;
}

.icon {
    height : 60px;
    width : 60px;
    border-radius: 50%;
    padding: 10px;
}