.services .icon-box:hover {
  background: red;
  border-color: #00A5A5;
}

.circledropdown {
  margin: 0;
  width: 10px;
  height: 10px;
  background: gray;
  -moz-border-radius: 70px;
  -webkit-border-radius: 70px;
  border-radius: 70px;
}


.serviceContainer {
  /* background-color: #fff; */
  /* box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%); */
  overflow-anchor: none;
  border-radius: 0;
  position: relative;
  /* border-left : 5px solid white */
}

.serviceContainer::before { 
  position: absolute;
  left: 0;
  top: -1px;
  right: 0;
  height: 1px;
  content: "";
  opacity: 1;
  /* background-color: rgba(0, 0, 0, 0.12); */
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

/* .serviceContainerRegular:hover {
  background-color: rgba(var(--themeRgb) , 0.2);
  border-left : 5px solid var(--theme)
} */

.serviceAccordian {
  background-color: #fff;
  border-left : 5px solid transparent;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.serviceAccordian::before {
  left: -5px !important;
}
  
.serviceAccordian:hover {
  /* background-color: rgba(var(--themeRgb) , 0.1) ; */
  /* background-color: red; */
  background: rgb(245 245 245 / 90%);
  border-left : 5px solid var(--theme);
  color: black;
  font-weight: 600;
}

.serviceAccordianExpanded {
  background-color: rgba(var(--themeRgb) , 0.1) !important;
  border-left : 5px solid var(--theme);
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
 
}


