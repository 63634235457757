/* .GoogleMaps {}
[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
  cursor: pointer;
 
} */
/* .col-container {
  display: flex;
  flex-wrap: wrap;
}
.col {
  flex: 1;
 
} */

.container12 {
  height: 250px;
}

.row1 {
  height: 100%;
}

.col1{
  /* background-color: red; */
  /* color: white; */
  height: 100%;
}

