.about_title {
    color: #00A5A5;
    text-align: left;
    font-weight: 700;
}

.about .icon-boxes h3 {
    font-size: 28px;
    font-weight: 700;
    color: #002dfc;
    margin-bottom: 15px;
}

.icon-box {
    margin-top: 40px;
}

.title {
    color: black;
    padding: 0px 0px 0px 14px;
    font-size: 17px;
    font-weight: 700;
}

.title_spe {
    color: black;
    padding: 0px 0px 0px 15px;
    font-size: 14px;
}

.icon_box {
    display: flex;
    margin-top: 30px;
}

.icon {
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    border: 2px solid #00A5A5;
    border-radius: 50px;
    transition: 0.5s;
}

.spec_container {
    margin-bottom: 30px;
}


.aboutModal { 
    border-radius: 1em;
    padding: 0;
}

.aboutModalHeader {
    padding : 0.6em 1.5em;
    text-align: center;
    font-size: 1.7rem;
    -webkit-box-shadow: 0 0 5px rgba(0 , 0, 0, 0.6);
    -moz-box-shadow: 0 0 5px rgba(0 , 0, 0, 0.6);
    box-shadow: 0 3px 5px -3px rgba(0 , 0, 0, 0.6);
}

.aboutModalContent {
    padding : 1em;
    height : 70vh;
    overflow: auto;
}

.aboutModalTitle { 
    display: inline-block;
    color : var(--theme);
    /* border-bottom: 2px solid var(--theme); */
}

.titleUnderLine {
    border-bottom: 2px solid var(--theme);
    border-radius: 5px;
    width : 70%;
    margin : auto;
}


.description {
    font-family : var(--font);
    overflow: hidden;
}

.description-full {
    width: auto;
    overflow: hidden;
    height : 361.5px;
    text-overflow: ellipsis;
}

.about-us-container{
    height : auto;
}

.readMore {
    color: var(--theme);
    cursor: pointer;
    margin-top: 1em;
    text-decoration: underline;
    font-weight: bold;
}

.about-img{
    height : 500px;
    width : 500px;
    float: left;
    margin-right: 2em;
}

.wrapper{
    -webkit-column-width: calc(100vw - 600px);
    column-width: calc(100vw - 600px);
    height: 100%;
}

@media (max-width : 768px) {
    .description-full{
        width : 91vw
    }

    .wrapper{
        -webkit-column-width: 91vw;
        column-width: 91vw;
    }
    
    .about-img{
        height : 250px;
        width : 91vw;
        margin: 0 auto 1em;
        float: none;
    }
}


