.container { 
    background-color: white;
    color : black;
    display : grid;
    grid-template-columns: repeat(auto-fit , 300px);
    justify-content: center;
    margin-bottom: 1.5em;
    /* align-items: center; */
    /* padding : 2em; */
    
}


.stat{
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    gap : 0.5em;
    padding : 0 1em 1em 1em

}

.statContent{
    background-color: white;
    text-align: center;
    border-radius: 10px;
    padding: 3em 1em 1em 1em;
    width : 80%;
    flex-grow: 1;
    word-break: break-word;
}


.icon {
    height : 60px;
    width : 60px;
    border-radius: 50%;
    padding: 10px;
    transform: translateY(calc(50% + 0.5em));
}