.container { 
  background-color: white;
  color : black;
  display : grid;
  grid-template-columns: repeat(auto-fit , 480px);
  justify-content: center;
  /* align-items: center; */
  gap : 1em
  /* padding : 2em; */
  
}


.doctors{
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap : 0.5em;
  padding : 1em;
  border-radius : 10px;
  word-break: break-word;

}