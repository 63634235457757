.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* .article-card:hover{
  color: blue;
  background-color:#dfe3e7;
}
.article-card:active{
  color: blue;
  background-color:#dfe3e7;
} */

.model{
 width:100%;
 margin:0em;
 height: 94%;
}

.react-responsive-modal-modal {
  max-width: 800px;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  background: #ffffff;
  box-shadow: 0 12px 15px 0 rgb(0 0 0 / 25%);
  /* margin: 1.2rem; */
  padding: 1.2rem;
  position: relative;
  overflow-y: auto;
}
.article-title{
  text-align: left;
  font-size: 20px;
  padding:10px 0px 0px 0px;
  font-weight:700;
  background-color: transparent;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  /* color: black; */
}

.article_text{
  text-align: left;
  padding:10px 0px 0px 0px;
  background-color: transparent;
  overflow: hidden;
  height : 250px
}

.article_text img {
  width : 100%;
}
.doctor_qualification{
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.article-discription{
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 50px;
}
.speciality_title{
  text-align: left;
  font-size: 22px;
  padding:10px 0px 0px 10px;
  font-weight:700;
  text-align: center;
  /* color: black; */
}
.speciality_discription{
  text-align: left;
  /* font-size: 15px; */
  padding:0px 0px 0px 10px;
  font-weight:600;
  /* color: black; */
}
.article-author{
  text-align: left;
  font-size: 12px;padding: 1px 0px 0px 0px;
  /* font-weight:600; */
  /* color: black; */
}
.about .icon-boxes h4 {
  font-size: 18px;
  color: #4b7dab;
  margin-bottom: 15px;
}

.about .icon-box {
  margin-top: 40px;
}
.about .icon-box .icon {
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  border: 2px solid #21689F;
  border-radius: 50px;
  transition: 0.5s;
}
.about .icon-box .icon i {
  color: #21689F;
  font-size: 32px;
}
.about .icon-box:hover .icon {
  background: #21689F;
  border-color: #21689F;
}
.about .icon-box:hover .icon i {
  color: #fff;
}
.about .icon-box .title {
  margin-left: 85px;
  font-weight: 700;
  margin-bottom: 10px;
  font-size: 18px;
}
.about .icon-box .title a {
  color: #21689F;
  transition: 0.3s;
}
.about .icon-box .title a:hover {
  color: #21689F;
}
.about .icon-box .description {
  margin-left: 85px;
  line-height: 24px;
  font-size: 14px;
}
.footer-title{
  color: 'white';
  font-size: '15px';
  text-align: left;
}

.about_title{
font-size: 15px;
text-align: justify;
}

.footer_name{
  font-size: 18px;
  text-align: justify;
  }
.stats_title{
  color: #ffffff;
   font-size: 25px;
}
.stats_discription{
color: #ffffff;
font-size: 15px;
}
.title_box{
  padding: 25px 0px;
  text-align: center;
  width: 65px;
  
  }
  .article_container{
    min-height: 400px;
     max-height: 400px; 
    border: 1px solid #000000;
    /* min-width: 350px; */
    max-width: 350px;
    margin-bottom: 10px;
    text-align: center;
    padding: 0px 20px 10px;
    background-color: white;
    color : black;  
    height: 50px;
  }
  
  .article_container:hover{
    background-color: var(--theme);
     color : #fff !important ; 
  }
  
 
  .specialities_container{
    min-height: 300px;
    max-height: 370px;
    border: 1px solid #00a5a5;
    min-width: 350px;
    max-width: 350px;
    margin-bottom: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 15px;
}
  
  .specialities_container * {
    pointer-events: none;
  }
  /* .article_container * {
    pointer-events: none;
  } */
  .article_pdf{
    pointer-events: auto;
  }
  .article_pdf *{
    pointer-events: auto;
  }
  .spe_title{
    color: black;
  }
 
  .spe_description{
    color: black;
  }
  .topbar {
    background: #fff;
    height: 40px;
    font-size: 14px;
    transition: all 0.5s;
    z-index: 996;
  }
  .topbar .topbar-scrolled {
    top: -40px;
  }
  .back-to-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: 15px;
    z-index: 996;
    background: #00A5A5;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    transition: all 0.4s;
  }
  .vertical-center {
    margin: auto;
    /* position: absolute; */
    /* top: 50%; */
    /* -ms-transform: translateY(-50%); */
    /* transform: translateY(-50%); */
  }

  
@media screen and (max-width: 992px) {
 
   
}


@media screen and (max-width: 600px) {
    .profile_image{
        min-width: 200px;
    }
    .custom_title{
      margin-top: 40px;
    }
    
}

@media (max-width: 767px) {
 
 
  #header {
  background: #fff;
 
  z-index: 997;
  padding: 15px 0;
  
  box-shadow: 0px 2px 15px rgba(25, 119, 204, 0.1);
  /* position: relative; */
  top: 10;
}
}
@media screen and (max-width: 1024px) {

.custom_title {
  margin-top: 40px;
}
.accordion:hover .accordion-item:hover .accordion-item-content,
.accordion .accordion-item--default .accordion-item-content {
    height: 6em;
}

.accordion-item-content,
.accordion:hover .accordion-item-content {
    height: 0;
    overflow: hidden;
    transition: height .25s;
}
}
.input:required {
  border-color: #800000;
  border-width: 3px;
}

.dispaly-center{
  display: flex;
  justify-content: space-evenly;
}
.map_button{

}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: #fff !important;
    border: 1px solid #63bcef;
    border-radius: 10%;
    background-color: #4d01ff;
    border-radius: 20px;
    width: 180px;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.MuiBox-root css-1gsv261{
  background-color: #000;
}
.css-1aquho2-MuiTabs-indicator {
  position: absolute;

  bottom: 0;
  width: 0;
  -webkit-transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  /* background-color: #1976d2; */
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #fff;
}
.css-1aquho2-MuiTabs-indicator {
  position: absolute;
  height: 0px !important;
  bottom: 0;
  width: 100%;
  -webkit-transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #fff;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: 0 0;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;

  border: 1px solid transparent;
  color: black;
  font-weight: 800;
  border-radius: 12%;
}
.nav-fill .nav-item .nav-link, .nav-justified .nav-item .nav-link {
   width: 30% !important;
}
.nav-fill .nav-item, .nav-fill>.nav-link {
    /* flex: 1 1 auto; */
    /* text-align: center; */
}





.nav-tabs {
  border-bottom: 1px solid transparent;
}

.nav-tabs .nav-link.active {
  color: #f8f9fa;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  background-color: transparent;
  border: 1px solid white;
  /* border-color: #dee2e6 #dee2e6 #fff; */
  width: 135px;
  border-radius: 8px;
}

.nav-tabs .nav-link {
  /* margin-bottom: -1px; */

  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  /* border: 1px solid #4d01ff; */
  color: black;
  font-weight: 800;
  border-radius: 8px;
  width: 135px;
  background-color: white;
}
.circletag {
  
  width: 40px;
  height: 40px;
  background: #dee2e6;
  /* For now you can actually use
   * plain border-radius
   *
   * -moz-border-radius: 20px;
   * -webkit-border-radius: 20px;
   */
  align-items: center;
  border-radius: 20px;
  margin-top: 0px;
 

}
.contact-info1 {
  width:150px;

}

.col-auto1 {

  width: 40px;
}

.col-auto2 {

  width: 190px;
}
html, body {
  overflow-x: hidden;
}
/* body {
  position: relative
} */


.carousel-indicator {
  border-top : none !important;
  border-bottom : none !important;
  height : 0.3em !important;
  width : 0.3em !important;
  border-radius: 50%;
}

.carousel-indicators .active { 
  /* color : "white" */
  height : 0.3em !important;
  width : 1.3em !important;
  background-color : blue !important;
  border-radius: 5px;
}

.white-color-footer span {
  color : white !important;
}
