.contactus_label {
    line-height: 2;
    text-align: left;
    display: block;
    margin-bottom: 13px;
    margin-top: 20px;
    color: #212529;
    font-size: 14px;
    font-weight: 200;
}
.contactuspanel_default {
    border:1px solid gainsboro;
    border-radius: 12px;
}