

.FrequentlyAskedQuestion{
    display: flex;
    justify-content: center;
    margin-top: 10px;
}
.FrequentlyAskedQuestion_container{
     width: 90%; 
}

.FrequentlyAskedQuestion_container:hover{
    background-color: rgb(245 245 245 / 90%);
}

.faqAccordian {
    background-color: #fff;
  }
  
    
  .faqAccordian:hover {
    /* background-color: rgba(var(--themeRgb) , 0.1) ; */
    /* background-color: red; */
    background-color: #f2f2f2;
    color : black;
    font-weight: 600;
  }
  
  .faqAccordianExpanded {
    background-color: #f2f2f2 !important;
    color : black;
    font-weight: 600;
  }
  