#scroll-wrapper {
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* p:active, h1:active, a:active {
  
  color:blue
<<<<<<< HEAD
}
.btn-check:focus+.btn, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0;
}
=======
} */
.btn-check:focus+.btn,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0;
}

@media (min-width : 768px) {
  .padding-right-desktop {
    padding-right: 3.5em;
  }
  .image_model{
    width: 95%;
  }
  .testimonials {
    min-width: 400px;
    max-width: 400px;
  }
}


@media (max-width : 768px) {
  .force-black-text span {
    color: black !important;
  }
  .testimonials {
    min-width: 400px;
    max-width: 400px;
  }
  .image_model{
    width: 95%;
  }

  .force-black-text p {
    color: black !important;
  }
  input[type="submit"],
.button {
  position: relative;
   background: #4d01ff; 
  color: white;
  text-transform: uppercase;
  border: none;
  /* font-weight: 600; */
  margin:20px;
  padding: 10px;
  font-size: 16px;
  /* letter-spacing: 2px; */
  display: block;
  appearance: none;
  border-radius: 25px;
  width: 25%;
  font-weight: 400;
  /* letter-spacing: 0.5rem; */
  transition: 0.3s all;
}
}

.model {

  /* width:90%; */
  margin: 0;
  max-width: 800px;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  background: #ffffff;
  box-shadow: 0 12px 15px 0 rgb(0 0 0 / 25%);
  /* margin: 1.2rem; */
  padding: 1.2rem;
  position: relative;
  overflow-y: auto;
  margin-left: '2px';
}

.react-responsive-modal-closeButton {
  position: absolute;
  top: 0px;
  right: 25px;
  border: none;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  display: flex;
}

.react-responsive-modal-container {
  height: 100%;
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: center;
  margin-left: -14px;
  margin-right: 3px;
  padding-right: 10px;
}

.react-responsive-modal-modal {
  max-width: 800px;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  background: #ffffff;
  box-shadow: 0 12px 15px 0 rgb(0 0 0 / 25%);
  margin: 1.2rem;
  padding: 1.2rem;
  position: relative;
  overflow-y: auto;
}

#topbar {
  background: #fff;
  height: 45px;
  font-size: 14px;
  /* transition: all 0.5s; */
  z-index: 996;
  scrollbar-gutter: stable;
  scrollbar-gutter: stable both-edges;
}

#topbar.topbar-scrolled {
  top: -45px;
  display: none !important;
}

#topbar .contact-info a {
  line-height: 1;
  color: #444444;
  /* transition: 0.3s; */
}

#topbar .contact-info a:hover {
  color: #1977cc;
}

#topbar .contact-info i {
  color: #1977cc;
  padding-right: 4px;
  margin-left: 15px;
  line-height: 0;
}

#topbar .contact-info i:first-child {
  margin-left: 0;
}

#topbar .social-links a {
  color: #437099;
  padding-left: 15px;
  display: inline-block;
  line-height: 1px;
  transition: 0.3s;
}

#topbar .social-links a:hover {
  color: #1977cc;
}

#topbar .social-links a:first-child {
  border-left: 0;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  background: #fff;
  /* transition: all 0.5s; */
  z-index: 997;
  padding: 15px 0;
  top: 40px;
  box-shadow: 0px 2px 15px rgba(25, 119, 204, 0.1);
}

#header .logo {
  font-size: 30px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 0.5px;
  font-family: "Poppins", sans-serif;
}

#header .logo a {
  color: #2c4964;
}

#header .logo img {
  max-height: 40px;
}

/**
* Appointment Button *
*/
.appointment-btn {
  margin-left: 25px;
  background: #00A5A5;
  color: #fff;
  border-radius: 50px;
  padding: 8px 25px;
  white-space: nowrap;
  transition: 0.3s;
  font-size: 14px;
  display: inline-block;
}

.appointment-btn:hover {
  background: #166ab5;
  color: #fff;
}

@media (max-width: 768px) {
  .appointment-btn {
    margin: 0 15px 0 0;
    padding: 6px 18px;
  }
  .testimonials {
    min-width: 400px;
    max-width: 400px;
  }
 
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

.fixed-top.sticky {
  top: 0 !important;
}

/* .articleModel {
  width: 80%;
  height: 100%;
  overflow-y: scroll;
  border-radius: 10px;
} */

.article_title {
  color: rgba(0, 0, 0, 1);
  font-weight: 700;
  font-size: 30px;
}

.article_discription {
  color: rgba(0, 0, 0, 0.8);
  font-weight: 500;
  font-size: 20px;
  word-break: break-word;
  padding: auto 20px;
}

.article_author {
  color: rgba(0, 0, 0, 0.5);
  font-weight: 500;
}

.section-header::after {

  position: relative;
  display: block;
  width: 120px;
  height: 3px;
  background: #1977cc;
  bottom: 0;
  left: calc(50% - 60px);
}

.iframe-loader {
  background: url(./assets/loading.gif) center center no-repeat;
}

.cursor-pointer {
  cursor: 'pointer';
  font-size: '14px'
}



@-webkit-keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }
  .image_model{
    width: 50%;
  }
  input[type="submit"],
  .button {
    position: relative;
     background: #4d01ff; 
    color: white;
    text-transform: uppercase;
    border: none;
    /* font-weight: 600; */
    margin:20px;
    padding: 10px;
    font-size: 16px;
    /* letter-spacing: 2px; */
    display: block;
    appearance: none;
    border-radius: 25px;
    width: 18%;
    font-weight: 400;
    /* letter-spacing: 0.5rem; */
    transition: 0.3s all;
  }
  .service_title {
    padding: 0px 0px 0px 60px;
  }

  .navbar .dropdown .dropdown:hover>ul {
    left: -100%;
  }

  .hr_tag {
    margin-left: 70px;
  }
}

@media (max-width: 460px) {
  .modal-body .tab {
    padding: 0px 0px 0px 0px;
  } .image_model{
    width: 95%;
  }
  input[type="submit"],
.button {
  position: relative;
   background: #4d01ff; 
  color: white;
  text-transform: uppercase;
  border: none;
  /* font-weight: 600; */
  margin:20px;
  padding: 10px;
  font-size: 16px;
  /* letter-spacing: 2px; */
  display: block;
  appearance: none;
  border-radius: 25px;
  width: 25%;
  font-weight: 400;
  /* letter-spacing: 0.5rem; */
  transition: 0.3s all;
}
  .testimonials {
    min-width: 400px;
    max-width: 400px;
  }

  .service-card {
    width: 95%;
    padding: 10px auto;
    background: #fff;
    /* height:520px; */
    overflow-y: scroll;
    border-radius: 5px;
    display: block;
    /* box-shadow: 5px 5px 5px 5px #dbdbdb; */
    box-shadow: 0px 2px 15px rgb(44 73 100 / 98%);
  }

  .card-container {
    /* display: flex; */
    /* justify-content: space-between; */
  }

  .hr_tag {
    margin-left: 0px;
  }
}



/* @media (max-height: 600px) {
  #hero {
    height: 25vh !important;
  }
  .service-card{
	width:95%;
	padding:10px auto;
	background:#fff;
	
	 overflow-y: scroll; 
 border-radius: 5px;
 display:block;

 box-shadow: 0px 2px 15px rgb(44 73 100 / 98%);
}
  #header {
  background: #fff;
 
  z-index: 997;
  padding: 15px 0;
  
  box-shadow: 0px 2px 15px rgba(25, 119, 204, 0.1);
}
  .counts {
  background: #e36ba8;
  padding: 20px 0 20px;
}
} */

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }
  .image_model{
    width: 50%;
  }
  input[type="submit"],
.button {
  position: relative;
   background: #4d01ff; 
  color: white;
  text-transform: uppercase;
  border: none;
  /* font-weight: 600; */
  margin:20px;
  padding: 10px;
  font-size: 16px;
  /* letter-spacing: 2px; */
  display: block;
  appearance: none;
  border-radius: 25px;
  width: 25%;
  font-weight: 400;
  /* letter-spacing: 0.5rem; */
  transition: 0.3s all;
}

  /* #hero {
    height: 30vh !important;
  } */
 
}

@media (max-width: 992px) {
  .tab button {
    margin-left: 0px !important;
  }
  input[type="submit"],
  .button {
    position: relative;
     background: #4d01ff; 
    color: white;
    text-transform: uppercase;
    border: none;
    /* font-weight: 600; */
    margin:20px;
    padding: 10px;
    font-size: 16px;
    /* letter-spacing: 2px; */
    display: block;
    appearance: none;
    border-radius: 25px;
    width: 25%;
    font-weight: 400;
    /* letter-spacing: 0.5rem; */
    transition: 0.3s all;
  }
  /* #hero {
    margin-bottom: 0;
    height: 50vh;
    /* width: 375px; */
  /* margin-top: 83px; */
  */ #hero .container {
    padding-bottom: 63px;
  }

  #hero h1 {
    font-size: 28px;
    line-height: 36px;
  }

  #hero h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }

  #hero h3 {
    font-weight: 700;
    font-size: 18px;
    margin-top: 80px;
  }
}

@media (min-width: 1024px) {
  #header.header-scrolled {
    top: 0px;

  }
  input[type="submit"],
.button {
  position: relative;
   background: #4d01ff; 
  color: white;
  text-transform: uppercase;
  border: none;
  /* font-weight: 600; */
  margin:20px;
  padding: 10px;
  font-size: 16px;
  /* letter-spacing: 2px; */
  display: block;
  appearance: none;
  border-radius: 25px;
  width: 18%;
  font-weight: 400;
  /* letter-spacing: 0.5rem; */
  transition: 0.3s all;
}
  .image_model{
    width: 50%;
  }
  .testimonials {
    min-width: auto;
    max-width: auto;
  } 
  #hero {
    /* background-attachment: fixed; */
    margin-top: 60px;
  }

  .service-card {
    width: 90%;
    padding: 10px auto;
    background: #fff;
    /* height:520px; */
    overflow-y: auto;
    border-radius: 5px;
    display: flex;
    /* box-shadow: 5px 5px 5px 5px #dbdbdb; */
    /* box-shadow: 0px 2px 15px rgb(44 73 100 / 98%); */
  }

  .card-pic {
    width: 100%;
  }

  .card-container {
    display: flex;
    /* justify-content: space-between; */
  }

  .custom-title {
    padding: 0px 70px;
  }

  .about_title {
    padding: 0px 70px;
  }

  .card {
    width: 70%;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #fff;
    border-radius: 1.25rem 1.25rem 0.70rem 0.70rem;
    box-shadow: 5px 5px 15px rgb(44 73 100 / 10%);
  }

  .custom-modal .modal-body .tab {
    padding: 0;
    width: 70%;
    margin: 0 auto;
    /* border: 1px solid #ddd; */
  }

  .custom-modal .modal-body .tabcontent {
    padding: 20px 40px;
    width: 70%;
    margin: 0 auto;
    border: 1px solid #ddd;
    background: #fff;
    max-height: 300px;
    overflow: auto;
  }

  .service_title {
    padding: 0px 0px 0px 40px;
  }

  .hr_tag {
    margin-left: 45px;
  }

  #hero h3 {
    font-weight: 700;
    font-size: 30px;
    margin-top: 80px;
  }

  #header {
    background: #fff;
    /* transition: all 0.5s; */
    z-index: 997;
    padding: 15px 0;
    top: 40px;
    box-shadow: 0px 2px 15px rgba(25, 119, 204, 0.1);
  }
}

@media (max-width: 768px) {
 
  .testimonials {
    min-width: 400px;
    max-width: 400px;
  } 
  input[type="submit"],
.button {
  position: relative;
   background: #4d01ff; 
  color: white;
  text-transform: uppercase;
  border: none;
  /* font-weight: 600; */
  margin:20px;
  padding: 10px;
  font-size: 16px;
  /* letter-spacing: 2px; */
  display: block;
  appearance: none;
  border-radius: 25px;
  width: 25%;
  font-weight: 400;
  /* letter-spacing: 0.5rem; */
  transition: 0.3s all;
}
  .image_model{
    width: 95%;
  }
  .container.header-margin {
    margin-top: 40px;
  }

  .custom-modal .modal-dialog {
    margin: 0 !important;
  }

  .service-card {
    width: 95%;
    padding: 10px auto;
    background: #fff;
    /* height:520px; */
    overflow-y: scroll;
    border-radius: 5px;
    display: block;
    /* box-shadow: 5px 5px 5px 5px #dbdbdb; */
    box-shadow: 0px 2px 15px rgb(44 73 100 / 98%);
  }

  .breadcrumbs .d-flex {
    display: block !important;
  }

  .custom-modal .modal-body .tab {
    padding: 0;
    width: 100%;
    margin: 0 auto;
    border: 1px solid #ddd;
  }

  .custom-modal .modal-body .tabcontent {
    padding: 20px 40px;
    width: 100%;
    margin: 0 auto;
    border: 1px solid #ddd;
    background: #fff;
  }

  .tabcontent .accordion {
    width: 100% !important;
  }

  .service_title {
    padding: 0px 0px 0px 0px;
  }

  .hr_tag {
    margin-left: 0px;
  }

  #header {
    background: #fff;
    /* transition: all 0.5s; */
    z-index: 997;
    padding: 15px 0;

    box-shadow: 0px 2px 15px rgba(25, 119, 204, 0.1);
  }

  .breadcrumbs ol {
    display: block;
  }

  .breadcrumbs ol li {
    display: inline-block;
  }

  #counts {
    background: #e36ba8;
    padding: 20px 0 20px;
  }
}


@-webkit-keyframes pulsate-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

@keyframes pulsate-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

@media (min-width: 1200px) {

  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1240px;
  }
}

@media (max-width: 1200px) {
  .faq .faq-list {
    padding: 0;
  }
  input[type="submit"],
  .button {
    position: relative;
     background: #4d01ff; 
    color: white;
    text-transform: uppercase;
    border: none;
    /* font-weight: 600; */
    margin:20px;
    padding: 10px;
    font-size: 16px;
    /* letter-spacing: 2px; */
    display: block;
    appearance: none;
    border-radius: 25px;
    width: 25%;
    font-weight: 400;
    /* letter-spacing: 0.5rem; */
    transition: 0.3s all;
  }
  .card-container {
    display: flex;
    /* justify-content: space-between; */
  }

  .card-pic {
    width: 100%;
  }

  .custom-modal .modal-body .tab {
    padding: 0;
    width: 70%;
    margin: 0 auto;
    /* border: 1px solid #ddd; */
  }

  .custom-modal .modal-body .tabcontent {
    padding: 20px 40px;
    width: 70%;
    margin: 0 auto;
    border: 1px solid #ddd;
    background: #fff;
  }

  .custom-modal .modal-body .tabcontent {
    width: 70%;
  }

  .custom-modal .modal-body .tab {
    width: 90%;
  }

}


@media (max-width: 767px) {
  .testimonials .testimonial-wrap {
    padding-left: 0;
  }

  input[type="submit"],
  .button {
    position: relative;
     background: #4d01ff; 
    color: white;
    text-transform: uppercase;
    border: none;
    /* font-weight: 600; */
    margin:20px;
    padding: 10px;
    font-size: 16px;
    /* letter-spacing: 2px; */
    display: block;
    appearance: none;
    border-radius: 25px;
    width: 25%;
    font-weight: 400;
    /* letter-spacing: 0.5rem; */
    transition: 0.3s all;
  }

  .container.header-margin {
    margin-top: 40px;
  }

  #header {
    background: #fff;
    /* transition: all 0.5s; */
    z-index: 997;
    padding: 15px 0;

    box-shadow: 0px 2px 15px rgba(25, 119, 204, 0.1);
    /* position: relative; */
    top: 0;
  }

  .testimonials .testimonials-carousel,
  .testimonials .testimonials-slider {
    overflow: hidden;
  }

  .testimonials .testimonial-item {
    padding: 30px;
    margin: 15px;
  }

  .testimonials .testimonial-item .testimonial-img {
    position: static;
    left: auto;
  }
}

@media (max-width: 991px) {
  .logoSection {
    order: 2;
    margin: 0 auto;
  }
  #navbar {
    /* justify-content: flex-end;
     flex-grow: 1; */
     display: none;
  }
  /* .navbar ul {
    display: none;
  } */
}
@media (min-width: 992px) {
  #navbar {
      justify-content: flex-end;
     flex-grow: 1; 
     display: flex;
  }
}

.accordion-button:focus {
  z-index: 3;
  border-color: #fff !important;
  border: 1px solid #fff !important;
  box-shadow: none !important;
  /* outline: 0; */
  /* box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%); */
}

.accordion-button:not(.collapsed) {
  color: #000;
  border: 1px solid #fff !important;
  /* background-color: #e7f1ff; */
  /* box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%); */
}

.custom-modal .modal-title div h2 {
  color: #e36ba8;
  position: relative;
}

.custom-modal .modal-title div h2 span {
  position: relative;
  width: 50px;
  height: 5px;
  background: #e36ba8;
  top: 10px;
  left: 48%;
  display: block;
  border-radius: 5px;
}

.pagination {
  display: flex;
  justify-content: flex-end;
}

/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/
.testimonials .testimonial-wrap {
  /* padding-left: 50px; */
}

.testimonials .testimonials-carousel,
.testimonials .testimonials-slider {
  overflow: hidden;
}

.testimonials .testimonial-item {
  box-sizing: content-box;
  padding: 30px 30px 30px 60px;
  margin: 30px 15px;
  min-height: auto;
  max-height: auto;
  box-shadow: 0px 2px 12px rgba(44, 73, 100, 0.08);
  position: relative;
  background: #fff;
  height: 250px;
}

.testimonials .testimonial-item .testimonial-img {
  width: 90px;
  border-radius: 10px;
  border: 6px solid #fff;
  position: absolute;
  left: -45px;
}

.testimonials .testimonial-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: #111;
}

.testimonials .testimonial-item h4 {
  font-size: 14px;
  color: #999;
  margin: 0;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
  color: #badaf7;
  font-size: 26px;
}

.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
}

.testimonials .testimonial-item p {
  font-style: italic;
  margin: 15px auto 15px auto;
  height: auto;
}

.testimonials .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #1977cc;
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #1977cc;
}

@media (max-width: 767px) {
  .testimonials .testimonial-wrap {
    padding-left: 0;
  }

  .testimonials .testimonials-carousel,
  .testimonials .testimonials-slider {
    overflow: hidden;
  }

  .testimonials .testimonial-item {
    padding: 30px;
    margin: 15px;
  }

  .testimonials .testimonial-item .testimonial-img {
    position: static;
    left: auto;
  }

  /* .testimonials {
    width: 500px;
  } */
}

/*--------------------------------------------------------------
# Gallery
--------------------------------------------------------------*/
.gallery .gallery-item {
  overflow: hidden;
  border-right: 3px solid #fff;
  border-bottom: 3px solid #fff;
}
.gallery .gallery-item img {
  transition: all ease-in-out 0.4s;
}
.gallery .gallery-item:hover img {
  transform: scale(1.1);
}
.testimonial-name{
  padding: 0px 3px;
  font-size: 18px;
    font-weight: bold;
    margin: 10px 0 5px 0;
    color: #111;
    display: -webkit-box;
    /* -webkit-box-orient: vertical; */
    /* -webkit-line-clamp: 1; */
    overflow: hidden;
}
.readMore-container{
  text-align: right;
}

.carousel-container{
  /* width: 100%; */
  padding-right : 15px;
}

.form {
  max-width: 800px;
  margin: 0 auto;
}

.p {
  color: #bf1650;
  text-align: center;
}

textarea,
select,
input {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  display: block;
  box-sizing: border-box;
  width: 35%;
  border-radius: 4px;
  border: 1px solid white;
  padding: 10px 15px;
  margin-bottom: 15px;
  font-size: 14px;
}

.label,
section > label {
  line-height: 2;
  text-align: left;
  display: block;
  margin-bottom: 13px;
  margin-top: 20px;
  color: white;
  font-size: 14px;
  font-weight: 200;
}


.contact-form{
  display: flex;
  justify-content:space-around;
}
.form-control:error {
  color: #212529;
  background-color: #fff;
  border-color: #000;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}
.contact-button{
  width: 170px;
    margin-bottom: 25px;
    height: 40px;
    margin-bottom: 50px !important;
}
.css-1a4cg4j-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #9c27b0;
  border: 1px solid black;
  border-radius: 10%;
}
.css-11yukd5-MuiTabs-indicator {
  position: absolute;
  /* height: 2px; */
  bottom: 0;
  width: 100%;
  -webkit-transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  /* background-color: #9c27b0; */
}
.css-1gsv261 {
  border-bottom: 1px solid #fff !important;
  border-color: rgba(0, 0, 0, 0.12);
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border: 1px solid black;
  /* border-color: #dee2e6 #dee2e6 #fff; */
  width: 200px;
}
.nav-tabs {
  border-bottom: 1px solid #fff;
}
