.navigation-icons{
    white-space: pre;
    cursor: pointer;
}

.navigation-pills{
    margin : 0.3em;
    border-radius : 50%;
    height : 32px;
    padding : 0 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
}

.selected{
    background-color: rgba(0,0,0,.08);
}

.disabled{
    color: rgba(0,0,0,.08);
}

