.readMore {
    color: var(--theme);
    cursor: pointer;
    margin-top: 1em;
    text-decoration: underline;
    font-weight: bold;
}
.testimonialModal { 
    border-radius: 1em;
    padding: 0;
}
.testimonialModalHeader {
    padding : 0.6em 1.5em;
    text-align: center;
    font-size: 1.7rem;
    -webkit-box-shadow: 0 0 5px rgba(0 , 0, 0, 0.6);
    -moz-box-shadow: 0 0 5px rgba(0 , 0, 0, 0.6);
    box-shadow: 0 3px 5px -3px rgba(0 , 0, 0, 0.6);
}

.testimonialModalContent {
    padding : 1em;
    height : 70vh;
    overflow: auto;
}

.testimonialModalTitle { 
    display: inline-block;
    color : var(--theme);
}
    
.titleUnderLine {
    border-bottom: 2px solid var(--theme);
    border-radius: 5px;
    width : 70%;
    margin : auto;
}
