.container { 
    background-color: white;
    color : black;
    display : grid;   
    justify-content: center;
    align-items: start;
    /* padding : 2em; */
    
}


.hours{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap : 0.5em;
    padding : 1em;
    border-radius : 10px;
    text-align: center;

}

.icon {
    height : 50px;
    width : 50px;
    border-radius: 50%;
    /* padding: 10px; */
}

.holidayIcon {
    height : 45px;
    width : 45px;
    /* border-radius: 50%; */
    /* padding: 10px; */
}