.pdf-container{
  /* height : 460px; */
  background-color: rgba(33, 37, 41, 0.95);
}

.navigator-container {
display: flex;
/* align-items: center; */
justify-content: center;
}



.pageInput {
  border: none; 
  background-color: transparent;
  color : white;
  padding : 0;
  margin : 0 10px 0 0;
  text-align: center;
}

.pageInput:focus {
  outline: none;
}
.pageInput:focus-visible {
  outline: none;
}

.pageInput::-webkit-outer-spin-button,
.pageInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.pageInput {
  -moz-appearance: textfield;
}

.pdf-page-container {
  display:  flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
    border-radius: 3px;
    background-color: rgba(33, 37, 41, 0.95);
  }

  .pdf-page{
    margin-bottom : 1em;
  }

  .toolbar{
    height: 50px;
    background-color: rgba(33, 37, 41, 0.949);
    display : flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.8em;
    color : white;
    padding : 0 1em
  }


  .mci-pdf-preview {
    background-color : rgba(33, 37, 41, 0.95);
    padding : 0 5px 5px 5px;
    display: flex;
  }

  .pdf-document{
    height: 80vh;
    overflow-y: overlay;
    width: 100%;
    overflow-x: clip;
  }


  .pdf-navigator {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: absolute;
    z-index: 2;
    background: white;
    height: 3em;
    width: 7.5em;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgb(0 0 0 / 0.2);
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
    /* opacity: 0; */
    transition: opacity ease-in-out 0.2s;
  }

  .pointer {
    cursor: pointer;
  }

  /* .sidebar-animation{
    animation: animateLeft 0.4s
  } */

  /* @keyframes animateLeft {
    0% {
     
      opacity: 0;
  }
  50%{
    opacity: 0.5;
  }
  100% {
  
      opacity: 1;
  }
  }
   */